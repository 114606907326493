import React, { useContext } from "react";
import {
  ContItems,
  SelectPuglinToPostBtn,
  DivBtnCerrar
} from "../../../../../assets/css/components/admin/marketplace-to-marketplace/modal-post-product/StepsStyle";
import { BtnGeneral } from "../../../../../assets/css/components/admin/marketplace-to-marketplace/modal-post-product/ModalContStyles";
import { GlobalContext } from "../../../../../contexts/GlobalContext";
import verifyThirdPartyUser from "../../../../general/VerifyThirdPartyUser";

export default function SelectPluginToPost({
  currentPlugin,
  setCurrentPlugin,
  nextStep,
}) {
  const globalContext = useContext(GlobalContext);

  const selectedpluginPost = (v) => {
    setCurrentPlugin(v);
    nextStep();
  };

  return (
    <ContItems>
      <DivBtnCerrar>
        <BtnGeneral
          bgc='#7367F0'
          color='#FFF'
          onClick={() => globalContext.setModalPostProduct(false)}
        >
          Cerrar
        </BtnGeneral>
      </DivBtnCerrar>

      <SelectPuglinToPostBtn
        active={currentPlugin === "ml"}
        onClick={() => {
          selectedpluginPost("ml");
        }}
        className='ml'
      >
        Mercado Libre
      </SelectPuglinToPostBtn>
      <SelectPuglinToPostBtn
        active={currentPlugin === "cashea"}
        onClick={() => {
          selectedpluginPost("cashea");
        }}
        className='cashea'
      >
        Cashea
      </SelectPuglinToPostBtn>
      {!verifyThirdPartyUser() &&
        <SelectPuglinToPostBtn
          active={currentPlugin === "py"}
          onClick={() => selectedpluginPost("py")}
          className='py'
        >
          Pedidos Ya
        </SelectPuglinToPostBtn>
      }
    </ContItems>
  );
}
