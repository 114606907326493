import styled from "styled-components";

export const ContItems = styled.div`
  min-width: 300px;
  max-width: 800px;
  display: flex;
  gap:18px;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  z-index: 50000;
`;

export const DivBtnCerrar = styled.div`
  position: absolute;
  right: 28rem;
  top: 1rem;
`;

export const ItemOption = styled.button.attrs((props) => ({
  bgc: props.active ? "#7367f02b" : "transparent",
  mr: props.mr || "0",
  ml: props.ml || "5px",
  br: props.active ? "none" : "1px solid #7367F0",
}))`
  min-width: 100px;
  max-width: 150px;
  padding: 7px;
  border-radius: 5px;
  outline: none !important;
  border: ${(props) => props.br};
  color: #7367f0;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-left: ${(props) => props.ml};
  margin-right: ${(props) => props.mr};
  background: ${(props) => props.bgc};
  &:disabled {
    background: #a7a4c12b;
    border: none;
  }
`;

export const SelectPuglinToPostBtn = styled.button`
  min-width: 280px;
  min-height: 115px;
  border-radius: 16px;
  padding: 0.5rem;
  display: flex;
  justify-content: center;
  background: #ffffff;
  align-items: center;
  box-shadow: 0px 0px 8px 0px rgb(19 18 66 / 7%);
  outline: none !important;
  transition: 0.5s ease;
  &.ml {
    border: 2px solid #fff159 !important;
  }
  &.ml:hover,
  &.ml:hover {
    background: #fff159;
    color: #7367f0 important;
  }

  &.ml:focus {
    background: #ebde51;
    transition: 0.1s;
  }

  &.py {
    border: 2px solid #f52f41 !important;
  }
  &.py:hover {
    background: #f52f41;
    color: #fff;
  }
  &.cashea {
    border: 2px solid #000000 !important;
  }
  &.cashea:hover {
    background: #fdfa3d;
    color: #7367f0 important;
  }

  &.cashea:focus {
    background: #ebde51;
    transition: 0.1s;
  }
  margin-left: 0.5rem;
  margin-right: 0.5rem;
  color: #170f49;
  font-weight: 500;
  font-size: 14pt;
  &:hover {
    border: 2px solid #7367f0 !important;
  }
`;

export const ContInputAndLabel = styled.div.attrs((props) => ({
  w: props.w || "300px",
  fld: props.fld || "column",
  jsc: props.jsc || "space-between",
  ali: props.ali || "flex-start",
  mr: props.mr || 0,
}))`
  height: auto;
  display: flex;
  width: ${(props) => props.w};
  flex-direction: ${(props) => props.fld};
  justify-content: ${(props) => props.jsc};
  align-items: ${(props) => props.ali};
  margin-right: ${(props) => props.mr};
`;

export const ListItemsToSend = styled.ul`
  width: 100%;
  list-style: none;
  padding-left: 0;
  display: flex;
  justify-content: flex-start;
  align-items: center;
`;
export const ItemToSend = styled.li`
  width: 100%;
  height: auto;
  background: #ffffff;
  padding: 10px;
  border: 1px solid #ebe9f1;
`;

export const ContGrid = styled.div`
  display: block;
  width: 100%;
  min-height: 500px;
  max-height: 700px;
  overflow-x: hidden;
  overflow-y: auto;
  margin-top: 10px;
  margin-bottom: 10px;
`;

export const ContGridCards = styled.div`
  max-width: 100%;
  width: 100%;
  display: grid;
  grid-gap: 2rem;
  grid-template-columns: repeat(auto-fit, minmax(280px, 280px));
  margin-top: 1rem;
`;
