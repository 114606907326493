import { apiRoutes } from "../../config";
import { api } from "../../lib/api";

export const uploadProducts = async ({
    enterpriseId,
    productIds,
    productAction
}) => {
    try {
        return (await api.post(
            apiRoutes.cashea.sendProducts,
            {
              enterpriseId,
              productIds,
              productAction
            }
        )).data;
    } catch (error) {
        let errorMessage = "Something went wrong trying on uploadProducts";
        if (error?.response?.data) errorMessage = error?.response?.data;
    
        throw new Error(errorMessage);
    }
}

export const bulkUpdateProducts = async ({
    brandName,
    categoryId,
    enterpriseId,
    productAction,
    merchantName,
    allMerchants
}) => {
    if(allMerchants) merchantName = undefined
    try {
        return (await api.post(
            apiRoutes.cashea.sendProducts,
            {
                brandName,
                categoryId,
                enterpriseId,
                productAction,
                merchantName,
                allMerchants
            }
        )).data;
    } catch (error) {
        let errorMessage = "Something went wrong trying on bulkUpdateProducts";
        if (error?.response?.data) errorMessage = error?.response?.data;
    
        throw new Error(errorMessage);
    }
}
export const fetchMerchantsAvailable = async () => {
    try {
        return (await api.get(
            apiRoutes.cashea.merchantsNames)).data;
    } catch (error) {
        let errorMessage = "Something went wrong trying on MerchantsNames";
        if (error?.response?.data) errorMessage = error?.response?.data;
    
        throw new Error(errorMessage);
    }
}

export const getMerchantKey = async ({name}) => {
    try {
        return (await api.get(`${apiRoutes.cashea.merchantsNames}`, {
            params: {
                name
            }
        }));
    } catch (error) {
        let errorMessage = "Something went wrong trying on getTrackingInfo";
        if (error?.response?.data) errorMessage = error?.response?.data;
    
        throw new Error(errorMessage);
    }
};


export const addMerchantsName = async ({
    enterpriseId,
    name,
    apiKey,
    tarifa,
    uptTarifa
}) => {
    try {
        return (await api.post(
            apiRoutes.cashea.merchantsNames,
            {
                enterpriseId,
                name,
                apiKey,
                tarifa,
                uptTarifa
            }
        )).data;
    
    } catch (error) {
        let errorMessage = "Something went wrong trying on MerchantsNames";
        if (error?.response?.data) errorMessage = error?.response?.data;
    
        throw new Error(errorMessage);
    }
}

export const syncMerchantsName = async (
    syncMerchant,
) => {
    try {
        return (await api.post(
            apiRoutes.cashea.syncMerchants,{
                ...syncMerchant
            })).data;
    
    } catch (error) {
        let errorMessage = "Something went wrong trying on MerchantsNames";
        if (error?.response?.data) errorMessage = error?.response?.data;
        
        throw new Error(errorMessage);
    }
}

export const deleteMerchantsName = async ({name}) => {
    try {
        return (await api.delete(
            apiRoutes.cashea.merchantsNames+'/'+name
            
        ));
    
    } catch (error) {
        let errorMessage = "Something went wrong trying on MerchantsNames";
        if (error?.response?.data) errorMessage = error?.response?.data;
    
        throw new Error(errorMessage);
    }
}

export const downloadCategory = async ({enterpriseId}) => {
    try {
        console.log(apiRoutes.cashea.getCategory)
        return (await api.post(apiRoutes.cashea.getCategory, {enterpriseId}).data);
    
    } catch (error) {
        let errorMessage = "Something went wrong trying on MerchantsNames";
        if (error?.response?.data) errorMessage = error?.response?.data;
    
        throw new Error(errorMessage);
    }
}

export const changeUrlCashea = async ({enterpriseId, urlBase}) => {
    try {
        console.log('Change url cashea', urlBase)
        console.log('Interprise', enterpriseId)
        return (await api.post(apiRoutes.cashea.changeUrlCashea, {enterpriseId, urlBase}).data);
    
    } catch (error) {
        let errorMessage = "Something went wrong trying on MerchantsNames";
        if (error?.response?.data) errorMessage = error?.response?.data;
    
        throw new Error(errorMessage);
    }
}

export const getUrlCashea = async () => {
    try {
        return (await api.get(
            apiRoutes.cashea.getUrlCashea)).data;
    } catch (error) {
        let errorMessage = "Something went wrong trying on MerchantsNames";
        if (error?.response?.data) errorMessage = error?.response?.data;
    
        throw new Error(errorMessage);
    }
}