import Admin from "./layouts/Admin";
import DebugScreen from "./components/admin/debug/DebugScreen";
//! COMPONENTS Admin
import Dashboard from "./components/admin/Dashboard";
import Productos from "./components/admin/Productos";
import Precios from "./components/admin/Precios";
import Plugins from "./components/admin/Plugins";
import Enterprices from "./components/admin/UserEnterprise";
import UserConfig from "./components/admin/UserConfig";
/**
 * ! Componenet Products
 */
import OdooToMl from "./components/admin/productos/new-product-forms/OdooToMl";
import MlToOdoo from "./components/admin/productos/new-product-forms/MlToOdoo";
import ProductCbt from "./components/admin/productos/ProductCbt";
import MassiveScraping from "./components/admin/productos/amazon/MassiveScraping.js";
import ListProductsCbt from './components/admin/productos/amazon/ListProducts.js'
import HistoryProductsCbt from './components/admin/productos/amazon/HistoryProductsCbt';
import ProductosMenu from "./components/admin/productos/product/ProductosMenu";
import NewProductsMenu from "./components/admin/productos/new-product-forms/NewProductsMenu";
import ProductDetail from "./components/admin/productos/product/ProductsDetail";
/**
 * ! import Componenets Prices
 */
import ListPrices from "./components/admin/precios/ListPrices";
/**
 * ! import Component Plugins
 */
import PluginsMenu from "./components/admin/plugins/PluginsMenu";
import MyPlugins from "./components/admin/plugins/MyPlugins";
import MyMenuPlugins from "./components/admin/plugins/MyPluginsMenu";
import MyOdooPlugin from "./components/admin/plugins/odoo/OdooConfig";
import FalabellaPlugin from "./components/admin/plugins/falabella";
import MyMercadoLibrePlugin from "./components/admin/plugins/mercadoLibre/MercadoLibreConf";
import MyMercadoLibrePluginSuccess from "./components/admin/plugins/mercadoLibre/MercadoLibreSuccess";
import MyAmazonPlugin from "./components/admin/plugins/amazon/AmazonConf";
import LinioConfig from "./components/admin/plugins/linio/LinioConfig";
import MyAliExpressPlugin from "./components/admin/plugins/aliExpress/AliExpressConf";
/**
 * ! import Componenet enterprise
 */
import UserEnterpriseNew from "./components/admin/user-enterprise/UserEnterpriseNew";
import UserEnterpriseDas from "./components/admin/user-enterprise/UserEnterpriseDas";
import UserEnterpriseUpdate from "./components/admin/user-enterprise/UserEnterpriseUpdate";
/**
 * ? img c
 * omponents grandsons
 */
import ImgCardOne from "./assets/img/jpeg/img-card-one.jpg";
import ImgCardTwo from "./assets/img/jpeg/img-card-two.jpg";
import ImgCardThree from "./assets/img/jpeg/img-card-three.jpg";
import ImgCardFour from "./assets/img/jpeg/img-card-four.jpg";
import ImgCardFive from "./assets/img/jpeg/img-card-five.jpg";
import ImgCardSix from "./assets/img/jpeg/img-card-six.jpg";
import UserBlockGrid from "./components/admin/user-config-componenets/UserBlockGrid";

import IndexRemoveBackground from "./components/admin/remove-background/views/IndexRemoveBackground"
import {
  faHome,
  faBox,
  faTags,
  faPuzzlePiece,
  faBoxes,
  faStore,
  faCog,
  faGenderless,
  faLanguage,
  faLink,
  faShoppingCart,
  faRobot,
  faFilter,
  faQuestion,
  faCity,
  faTable,
  faHistory,
  faRoute,
  faChartBar,
  faExchangeAlt,
  faRemoveFormat,
  faShoppingBag,
  faBuilding
} from "@fortawesome/free-solid-svg-icons";
import Rates from "./components/admin/Rates";

import RatesDetail from "./components/admin/RatesDetail";
import TranslateCategorie from "./components/admin/categories/TranslateCategorie";
import LinkCategories from "./components/admin/categories/LinkCategories";
import LinkCity from "./components/admin/city/LinkCity";
import FormPlantilla from "./components/admin/productos/mercado-libre/FormPlantilla";


// Testing
import TestingAdminEnterprise from "./components/testing/adminEnterprise"
import TestingAdmin from "./components/testing/admin"
import Index from "./components/admin/productos/mercado-libre/rangos-publicacion/Index";
import LinkeoMasivo from "./components/admin/productos/LinkeoMasivo";
import IndexPost from "./components/admin/productos/public-product/IndexPost";
import UploadProductsXlsx from "./components/testing/uploadProductsXlsx";
import deletedProductsMLXlsx from "./components/testing/deletedProductsMLXlsx";
import uploadProductsPeyaXlsx from "./components/testing/uploadProductsPeyaXlsx";
import DafitiConfig from "./components/admin/plugins/dafiti/DafitiConfig";
import CasheaConfig from "./components/admin/plugins/cashea";
import DetailProduct from "./components/admin/productos/amazon/DetailProduct";
import Grid from "./components/admin/productos/orders/Grid";
import DetailProductOrders from "./components/admin/productos/orders/DetailProductOrders";
import IndexOrders from "./components/admin/productos/orders/IndexOrders";
import AnotherScraping from "./components/admin/productos/AnotherScraping";
import ListFilters from "./components/admin/filters/views/ListFilters";
import FormFilters from "./components/admin/filters/views/FormFilters";
import UpdateFilters from "./components/admin/filters/views/UpdateFilters";
import QuestionsList from "./components/admin/ml-questions/views/QuestionsList";
import Filters from "./components/admin/Filters";
import IndexConfig from "./components/admin/config/IndexConfig";
import UserConfigNewUser from "./components/admin/config/UserConfigNewUser";
import UserConfigUpdateUser from "./components/admin/config/UserConfigUpdateUser";
import UserConfigUpdateUserAdmin from './components/admin/config/UserConfigUpdateUserAdmin';
import UserConfigNewUserAdmin from './components/admin/config/UserConfigNewUserAdmin';
import ListProducts from "./components/admin/productos/product/ListProducts";
import ListOfProducts from "./components/admin/update-and-publish/views/ListOfProducts";
import CronErrors from "./components/admin/cron-reports/views/CronErrors";
import LinioCampaings from "./components/admin/linio-campaign/view/LinioCampaings";
import DfCampaings from "./components/admin/df-campaign/view/DfCampaings";
import ScrapingUrl from "./components/admin/productos/amazon/masive-scraping-url/views/ScrapingUrl";
import FormIndex from "./components/admin/plugins/pedidosYa/views/FormIndex";
import FormIndexAnican from "./components/admin/plugins/anican/views/FormIndex";
import GridReports from "./components/admin/reports/amazon/GridReports";
import GridListProducts from "./components/admin/productos/public-products-m-to-m/views/GridListProducts";
import UploadUrls from "./components/testing/UploadUrls";
import AdminWoo from "./components/testing/AdminWoo";
import UploadCategoriesXlsx from "./components/testing/uploadCategoriesXlsx";
import Duplicate from "./components/testing/Duplicate";
import UpdateCategoriesXlsx from "./components/testing/updateCategoriesXlsx";
import UpdateProductsXlsx from "./components/testing/UpdateProductsXlsx";
import UpdateProductsXlsxAmazo from "./components/testing/UpdateProductsXlsxAmazon";
import DeleteByExcellMLKBT from "./components/testing/DeleteByExcellMLKBT";
import FormIndexWoo from "./components/admin/plugins/woo/views/FormIndexWoo";
import FormConfigMercantil from "./components/admin/plugins/mercantil/views/FormConfigMercantil";
import FormIndexWooCampaign from "./components/admin/plugins/woo/views/FormIndexWooCampaign";
import CasheaCampaign from "./components/admin/plugins/cashea/campaigns/index";
import DetailProductMTM from "./components/admin/productos/public-products-m-to-m/views/DetailProductMTM";
import ShowProductInfo from "./components/admin/productos/ShowProductInfo";
import verifyThirdPartyUser from "./components/general/VerifyThirdPartyUser.js";
// Testing end


/**
 * faPowerOff,
  faUserCog,
  faSun,
 */
const Routes = [
  //rutas layouts

  {
    father: "",
    path: "/admin",
    name: "admin",
    component: Admin,
    layout: "/admin",
    isFather: false,
    son: false,
    grandSon: false,
    icon: faHome,
    img: '',
    idMatch: 1,
  },
  /**
   * !rutas para los formularios login y sigin
   */

  /**
   * !rutas admin fhaters
   */
  {
    father: "admin",
    path: "/dashboard",
    name: "Dashboard",
    component: Dashboard,
    layout: "/admin",
    isFather: true,
    son: false,
    grandSon: false,
    icon: faHome,
    img: "",
    idMatch: 2,
    rolAccess: [
      'SuperAdmin',
      'Admin',
      'Gerente',
      'Champion',
      'Preguntas'
    ],
    enterpriseBlock: [],
    third: true,
  },
  {
    father: "admin",
    path: "/productos",
    name: "Productos",
    component: Productos,
    layout: "/admin",
    isFather: true,
    son: false,
    grandSon: false,
    icon: faBox,
    img: "",
    idMatch: 3,
    rolAccess: [
      'SuperAdmin',
      'Admin',
      'Gerente',
      'Champion'
    ],
    enterpriseBlock: []
  },
  {
    father: "admin",
    path: "/list-products-cbt-history-products",
    name: "Historial de Productos KBT",
    component: HistoryProductsCbt,
    layout: "/admin",
    isFather: true,
    son: false,
    grandSon: false,
    icon: faHistory,
    img: ImgCardFour,
    idMatch: 37,
    rolAccess: [
      'SuperAdmin',
      'Admin',
      'Gerente',
      'Champion',
    ],
    enterpriseBlock: []
  },

  {
    father: "admin",
    path: "/orders",
    name: "Pedidos",
    component: IndexOrders,
    layout: "/admin",
    isFather: true,
    son: false,
    grandSon: false,
    icon: faShoppingCart,
    img: "",
    idMatch: 4,
    rolAccess: [
      'SuperAdmin',
      'Admin',
      'Gerente',
      'Champion'
    ],
    enterpriseBlock: [],
    third: true,
  },
  {
    father: "admin",
    path: "/user-config",
    name: "Configuracion",
    component: UserConfig,
    layout: "/admin",
    isFather: true,
    son: false,
    grandSon: false,
    icon: faCog,
    img: "",
    idMatch: 5,
    rolAccess: [
      'SuperAdmin',
      'Admin',
      'Gerente',
    ],
    enterpriseBlock: []
  },
  {
    father: "/user-config",
    path: "/index",
    name: "Configuracion",
    component: IndexConfig,
    layout: "/admin",
    isFather: false,
    son: true,
    grandSon: false,
    icon: faCog,
    img: "",
    idMatch: 6,
    rolAccess: [
      'SuperAdmin',
      'Admin',
      'Gerente',
      'Champion',
    ],
    enterpriseBlock: []
  },
  {
    father: "/user-config",
    path: "/new-user",
    name: "Nuevo usuario",
    component: UserConfigNewUser,
    layout: "/admin",
    isFather: false,
    son: true,
    grandSon: false,
    icon: faCog,
    img: "",
    idMatch: 7,
    rolAccess: [
      'SuperAdmin',
      'Admin',
    ],
    enterpriseBlock: []
  },

  {
    father: "/user-config",
    path: "/user-block-grid",
    name: "Bloqueo de usuarios",
    component: UserBlockGrid,
    layout: "/admin",
    isFather: false,
    son: true,
    grandSon: false,
    icon: faCog,
    img: "",
    idMatch: 8,
    rolAccess: [
      'SuperAdmin',
      'Admin',
      'Gerente',
      'Champion',
    ],
    enterpriseBlock: []
  },
  {
    father: "/user-config",
    path: "/update-user/:id",
    name: "Nuevo usuario",
    component: UserConfigUpdateUser,
    layout: "/admin",
    isFather: false,
    son: true,
    grandSon: false,
    icon: faCog,
    img: "",
    idMatch: 9,
    rolAccess: [
      'SuperAdmin',
      'Admin',
    ],
    enterpriseBlock: []
  },
  {
    father: "/user-config",
    path: "/update-user-admin/:id",
    name: "Nuevo usuario",
    component: UserConfigUpdateUserAdmin,
    layout: "/admin",
    isFather: false,
    son: true,
    grandSon: false,
    icon: faCog,
    img: "",
    idMatch: 10,
    rolAccess: [
      'SuperAdmin',
      'Admin',
    ],
    enterpriseBlock: []
  },
  {
    father: "/user-config",
    path: "/new-user-admin/",
    name: "Nuevo usuario",
    component: UserConfigNewUserAdmin,
    layout: "/admin",
    isFather: false,
    son: true,
    grandSon: false,
    icon: faCog,
    img: "",
    idMatch: 10,
    rolAccess: [
      'SuperAdmin',
      'Admin',
    ],
    enterpriseBlock: []
  },
  {
    father: "admin",
    path: "/precios",
    name: "Precios",
    component: Precios,
    layout: "/admin",
    isFather: false, //change
    son: false,
    grandSon: false,
    icon: faTags,
    img: "",
    idMatch: 11,
    rolAccess: [
      'SuperAdmin',
      'Admin',
    ],
  },
  {
    father: "admin",
    path: "/rates-ml",
    name: "Campañas ML",
    component: Rates,
    layout: "/admin",
    isFather: true, //change
    son: false,
    grandSon: false,
    icon: faTags,
    img: "",
    idMatch: 12,
    rolAccess: [
      'SuperAdmin',
      'Admin',
      'Gerente',
    ],
    enterpriseBlock: [
      '612080ad8bdd953895401cda',
      '612080d82a7d172e457520ba'
    ]
  },
  {
    father: "admin",
    path: "/campaings-linio",
    name: "Campañas de Linio",
    component: LinioCampaings,
    layout: "/admin",
    isFather: true,
    son: false,
    grandSon: false,
    icon: faTags,
    img: "",
    idMatch: 13,
    rolAccess: [
      'SuperAdmin',
      'Admin',
      'Gerente',
    ],
    enterpriseBlock: [
      '5f59092f5f8c84465e058171',
      '612080d82a7d172e457520ba'
    ]
  },
  {
    father: "admin",
    path: "/campaings-df",
    name: "Campañas de Dafiti",
    component: DfCampaings,
    layout: "/admin",
    isFather: true,
    son: false,
    grandSon: false,
    icon: faTags,
    img: "",
    idMatch: 14,
    rolAccess: [
      'SuperAdmin',
      'Admin',
      'Gerente',
    ],
    enterpriseBlock: [
      '5f59092f5f8c84465e058171',
      '612080ad8bdd953895401cda'
    ],
  },
  {
    father: "admin",
    path: "/filters",
    name: "Filtros para Publicaciones",
    component: Filters,
    layout: "/admin",
    isFather: true, //change
    son: false,
    grandSon: false,
    icon: faFilter,
    img: "",
    idMatch: 15,
    rolAccess: [
      'SuperAdmin',
      'Admin',
      'Gerente',
    ],
    enterpriseBlock: []
  },
  {
    father: "admin",
    path: "/cron-errors",
    name: "Reporte del cron",
    component: CronErrors,
    layout: "/admin",
    isFather: true, //change
    son: false,
    grandSon: false,
    icon: faTable,
    img: "",
    idMatch: 16,
    rolAccess: [
      'SuperAdmin',
      'Admin',
      'Gerente',
    ],
    enterpriseBlock: []
  },
  {
    father: "/filters",
    path: "/list",
    name: "Filtros para Publicaciones",
    component: ListFilters,
    layout: "/admin",
    isFather: false, //change
    son: true,
    grandSon: false,
    icon: faFilter,
    img: "",
    idMatch: 17,
    rolAccess: [
      'SuperAdmin',
      'Admin',
      'Gerente',
      'Champion',
    ], enterpriseBlock: []
  },
  {
    father: "/filters",
    path: "/create",
    name: "Agregar Filtros",
    component: FormFilters,
    layout: "/admin",
    isFather: false, //change
    son: true,
    grandSon: false,
    icon: faFilter,
    img: "",
    idMatch: 18,
    rolAccess: [
      'SuperAdmin',
      'Admin',
      'Gerente',
      'Champion',
    ], enterpriseBlock: []
  },
  {
    father: "/filters",
    path: "/update/:id",
    name: "Actualizar Filtros",
    component: UpdateFilters,
    layout: "/admin",
    isFather: false, //change
    son: true,
    grandSon: false,
    icon: faFilter,
    img: "",
    idMatch: 19,
    rolAccess: [
      'SuperAdmin',
      'Admin',
      'Gerente',
      'Champion',
    ], enterpriseBlock: []
  },
  {
    father: "admin",
    path: "/ml-questions",
    name: "Preguntas ML",
    component: QuestionsList,
    layout: "/admin",
    isFather: true, //change
    son: false,
    grandSon: false,
    icon: faQuestion,
    img: "",
    idMatch: 20,
    rolAccess: [
      'SuperAdmin',
      'Admin',
      'Gerente',
      'Champion',
      'Preguntas'
    ], enterpriseBlock: []
  },
  {
    father: "admin",
    path: "/rates-detail/:campaignId",
    name: "Rates Detail",
    component: RatesDetail,
    layout: "/admin",
    isFather: false, //change
    son: true,
    grandSon: false,
    icon: faTags,
    img: "",
    idMatch: 21,
    rolAccess: [
      'SuperAdmin',
      'Admin',
      'Gerente',
      'Champion',
    ],
  },
  {
    father: "/productos",
    path: "/public-product/:idProduct",
    name: "Publicacion de productos",
    component: IndexPost,
    layout: "/admin",
    isFather: false, //change
    son: true,
    grandSon: false,
    icon: faTags,
    img: "",
    idMatch: 22,
    rolAccess: [
      'SuperAdmin',
      'Admin',
      'Gerente',
      'Champion'
    ],
  },
  {
    father: "admin",
    path: "/plugins/my-plugins",
    name: "Plugins",
    component: Plugins,
    layout: "/admin",
    isFather: true,
    son: false,
    grandSon: false,
    icon: faPuzzlePiece,
    img: "",
    idMatch: 23,
    rolAccess: [
      'SuperAdmin',
      'Admin',
      'Gerente',
    ],
    enterpriseBlock: [],
    third: true,
  },
  {
    father: "/orders",
    path: "/grid",
    name: "Pedidos",
    component: Grid,
    layout: "/admin",
    isFather: false,
    son: true,
    grandSon: false,
    icon: faShoppingCart,
    img: "",
    idMatch: 24,
    rolAccess: [
      'SuperAdmin',
      'Admin',
      'Gerente',
      'Champion'
    ],
    third: true,
  },
  {
    father: "/orders",
    path: "/detail/:plugin/:idAmazon/:idOrder",
    name: "Detalles del pedido",
    component: DetailProductOrders,
    layout: "/admin",
    isFather: false,
    son: true,
    grandSon: true,
    icon: faShoppingCart,
    img: "",
    idMatch: 25,
    rolAccess: [
      'SuperAdmin',
      'Admin',
      'Gerente',
      'Champion'
    ],
  },

  {
    father: "admin",
    path: "/enterprise",
    name: "Empresas del Usuario",
    component: Enterprices,
    layout: "/admin",
    isFather: true,
    son: false,
    grandSon: false,
    icon: faStore,
    img: "",
    idMatch: 26,
    rolAccess: [
      'SuperAdmin',
      'Admin',
      'Gerente',
      'Champion',
    ], enterpriseBlock: []
  },

  //* ruts products childc
  {
    father: "/productos",
    path: "/menu-product",
    name: "Menu de Producto",
    component: ProductosMenu,
    layout: "/admin",
    isFather: false,
    son: false,
    grandSon: false,
    icon: faPuzzlePiece,
    img: ImgCardOne,
    idMatch: 27,
    rolAccess: [
      'SuperAdmin',
      'Admin',
      'Gerente',
      'Champion',
    ]
  },
  // {
  //   father: "/productos",
  //   path: "/new-product",
  //   name: "Nuevo Producto",
  //   component: NewProduct,
  //   layout: "/admin",
  //   isFather: false,
  //   son: true,
  //   grandSon: true,
  //   icon: faPuzzlePiece,
  //   img: ImgCardOne,
  // },
  {
    father: "/productos/new-product",
    path: "/new-product-menu",
    name: "Nuevo Producto",
    component: NewProductsMenu,
    layout: "/admin",
    isFather: false,
    son: false,
    grandSon: false,
    icon: faPuzzlePiece,
    img: ImgCardOne,
    idMatch: 28,
    rolAccess: [
      'SuperAdmin',
      'Admin',
      'Gerente',
      'Champion',
    ]
  },
  {
    father: "/productos/new-product",
    path: "/odoo-to-ml",
    name: "Odoo a Mercado libre",
    component: OdooToMl,
    layout: "/admin",
    isFather: false,
    son: true,
    grandSon: true,
    icon: faPuzzlePiece,
    img: ImgCardFour,
    idMatch: 29,
    rolAccess: [
      'SuperAdmin',
      'Admin',
      'Gerente',
      'Champion',
    ]
  },
  {
    father: "/productos/new-product",
    path: "/ml-to-odoo",
    name: "Mercado Libre a Odoo",
    component: MlToOdoo,
    layout: "/admin",
    isFather: false,
    son: true,
    grandSon: true,
    icon: faPuzzlePiece,
    img: ImgCardSix,
    idMatch: 30,
    rolAccess: [
      'SuperAdmin',
      'Admin',
      'Gerente',
      'Champion',
    ]
  },
  //todo descomentar
  // {
  //   father: "/productos",
  //   path: "/list-products",
  //   name: "Lista de Productos",
  //   component: GridProducts,
  //   layout: "/admin",
  //   isFather: false,
  //   son: true,
  //   grandSon: true,
  //   icon: faBoxes,
  //   img: ImgCardThree,
  // },
  //todo comenmtar
  {
    father: "/productos",
    path: "/list-products",
    name: "Lista de Productos",
    component: ListProducts,
    layout: "/admin",
    isFather: false,
    son: true,
    grandSon: true,
    icon: faBoxes,
    img: ImgCardThree,
    idMatch: 31,
    rolAccess: [
      'SuperAdmin',
      'Admin',
      'Gerente',
      'Champion',
    ]
  },
  {
    father: "admin",
    path: "/list-products-cbt",
    name: "Scraping Individual",
    component: ProductCbt,
    layout: "/admin",
    isFather: true,
    son: false,
    grandSon: false,
    icon: faGenderless,
    img: ImgCardFour,
    idMatch: 32,
    rolAccess: [
      'SuperAdmin',
      'Admin',
      'Gerente',
      'Champion',
    ],
    enterpriseBlock: []
  },

  {
    father: "/productos",
    path: "/scraping-by-url",
    name: "Scraping masivo de URLs",
    component: ScrapingUrl,
    layout: "/admin",
    isFather: true,
    son: false,
    grandSon: false,
    icon: faRoute,
    img: ImgCardFour,
    idMatch: 33,
    rolAccess: [
      'SuperAdmin',
      'Admin',
    ],
    enterpriseBlock: []
  },
  {
    father: "admin",
    path: "/masive-scraping",
    name: "Scraping masivo",
    component: MassiveScraping,
    layout: "/admin",
    isFather: true,
    son: false,
    grandSon: false,
    icon: faGenderless,
    img: ImgCardFour,
    idMatch: 34,
    rolAccess: [
      'SuperAdmin',
      'Admin',
    ],
    enterpriseBlock: []
  },
  {
    father: "/productos",
    path: "/list-products-cbt-list-products",
    name: "Lista de Productos CBT",
    component: ListProductsCbt,
    layout: "/admin",
    isFather: false,
    son: false,
    grandSon: false,
    icon: faBoxes,
    img: ImgCardFour,
    idMatch: 35,
    rolAccess: [
      'SuperAdmin',
      'Admin',
      'Gerente',
      'Champion',
    ]
  },
  {
    father: "/productos",
    path: "/update-and-publish",
    name: "Lista de Productos CBT",
    component: ListOfProducts,
    layout: "/admin",
    isFather: false,
    son: false,
    grandSon: false,
    icon: faBoxes,
    img: ImgCardFour,
    idMatch: 36,
    rolAccess: [
      'SuperAdmin',
      'Admin',
      'Gerente',
      'Champion',
    ]
  },
  // {
  //   father: "/productos",
  //   path: "/list-products-cbt-history-products",
  //   name: "Historial de Productos CBT",
  //   component: HistoryProductsCbt,
  //   layout: "/admin",
  //   isFather: false,
  //   son: false,
  //   grandSon: false,
  //   icon: faBoxes,
  //   img: ImgCardFour,
  //   idMatch: 37,
  //   rolAccess: [
  //     'SuperAdmin',
  //     'Admin',
  //     'Gerente',
  //     'Champion',
  //   ]
  // },

  {
    father: "/productos",
    path: "/detail-kbt/:id",
    name: "Detalle del Producto",
    component: DetailProduct,
    layout: "/admin",
    isFather: false,
    son: false,
    grandSon: false,
    icon: faBoxes,
    img: ImgCardFour,
    idMatch: 38,
    rolAccess: [
      'SuperAdmin',
      'Admin',
      'Gerente',
      'Champion',
    ]
  },
  {
    father: "/productos",
    path: "/detail-products/:plugin/:id",
    name: "Detalle del Producto",
    component: ProductDetail,
    layout: "/admin",
    isFather: false,
    son: false,
    grandSon: true,
    icon: faBoxes,
    img: ImgCardThree,
    idMatch: 39,
    rolAccess: [
      'SuperAdmin',
      'Admin',
      'Gerente',
      'Champion',
    ]
  },
  //* ruts precios child
  {
    father: "Precios",
    path: "/precios/list-prices",
    name: "Lista de Precios",
    component: ListPrices,
    layout: "/admin",
    isFather: false,
    son: true,
    grandSon: true,
    icon: faBoxes,
    img: ImgCardThree,
    idMatch: 40,
    rolAccess: [
      'SuperAdmin',
      'Admin',
      'Gerente',
      'Champion',
    ]
  },
  //* ruts plugins child
  {
    father: "/plugins",
    path: "/menu-plugins",
    name: "Menu Plugins",
    component: PluginsMenu,
    layout: "/admin",
    isFather: false,
    son: false,
    grandSon: false,
    icon: faPuzzlePiece,
    img: ImgCardOne,
    idMatch: 41,
    rolAccess: [
      'SuperAdmin',
      'Admin',
      'Gerente',
      'Champion',
    ]
  },
  {
    father: "/plugins",
    path: "/my-plugins",
    name: "My Plugins",
    component: MyPlugins,
    layout: "/admin",
    isFather: false,
    son: true,
    grandSon: true,
    icon: faPuzzlePiece,
    img: ImgCardFour,
    idMatch: 43,
    rolAccess: [
      'SuperAdmin',
      'Admin',
      'Gerente',
      'Champion',
    ],
    third: true,
  },
  //* Sons' my-plugins begin
  {
    father: "/plugins/my-plugins",
    path: "/my-menu-plugins",
    name: "Menu Plugins",
    component: MyMenuPlugins,
    layout: "/admin",
    isFather: false,
    son: false,
    grandSon: false,
    icon: faPuzzlePiece,
    img: "",
    idMatch: 44,
    rolAccess: [
      'SuperAdmin',
      'Admin',
      'Gerente',
      'Champion',
    ],
    third: true,
  },
  {
    father: "/plugins/my-plugins",
    path: "/odoo-config",
    name: "Odoo Plugin",
    component: MyOdooPlugin,
    layout: "/admin",
    isFather: false,
    son: true,
    grandSon: true,
    icon: faPuzzlePiece,
    img: ImgCardTwo,
    idMatch: 45,
    rolAccess: [
      'SuperAdmin',
      'Admin',
      'Gerente',
      'Champion',
    ]
  },
  {
    father: "/plugins/my-plugins",
    path: "/falabela-config",
    name: "Falabella Plugin",
    component: FalabellaPlugin,
    layout: "/admin",
    isFather: false,
    son: true,
    grandSon: true,
    icon: faPuzzlePiece,
    img: ImgCardTwo,
    idMatch: 66,
    rolAccess: [
      'SuperAdmin',
      'Admin',
      'Gerente',
      'Champion',
    ]
  },
  {
    father: "/plugins/my-plugins",
    path: "/mercado-libre-config",
    name: "Mercado Libre Plugin",
    component: MyMercadoLibrePlugin,
    layout: "/admin",
    isFather: false,
    son: true,
    grandSon: true,
    icon: faPuzzlePiece,
    img: ImgCardFive,
    idMatch: 46,
    rolAccess: [
      'SuperAdmin',
      'Admin',
      'Gerente',
      'Champion',
    ],
    third: true,
  },
  {
    father: "/plugins/my-plugins",
    path: "/ali-express-config",
    name: "Ali Express Plugin",
    component: MyAliExpressPlugin,
    layout: "/admin",
    isFather: false,
    son: true,
    grandSon: true,
    icon: faPuzzlePiece,
    img: ImgCardFive,
    idMatch: 46,
    rolAccess: [
      'SuperAdmin',
      'Admin',
      'Gerente',
      'Champion',
    ]
  },
  {
    father: "/plugins/my-plugins",
    path: "/mercado-libre-success",
    name: "Mercado Libre Plugin",
    component: MyMercadoLibrePluginSuccess,
    layout: "/admin",
    isFather: false,
    son: false,
    grandSon: false,
    icon: faPuzzlePiece,
    img: ImgCardFive,
    idMatch: 47,
    rolAccess: [
      'SuperAdmin',
      'Admin',
      'Gerente',
      'Champion',
    ]
  },
  {
    father: "/plugins/my-plugins",
    path: "/amazon-config",
    name: "Amazon Plugin",
    component: MyAmazonPlugin,
    layout: "/admin",
    isFather: false,
    son: true,
    grandSon: true,
    icon: faPuzzlePiece,
    img: ImgCardSix,
    idMatch: 48,
    rolAccess: [
      'SuperAdmin',
      'Admin',
      'Gerente',
      'Champion',
    ]
  },
  {
    father: "/plugins/my-plugins",
    path: "/linio-config",
    name: "Linio Plugin",
    component: LinioConfig,
    layout: "/admin",
    isFather: false,
    son: true,
    grandSon: true,
    icon: faPuzzlePiece,
    img: ImgCardOne,
    idMatch: 49,
    rolAccess: [
      'SuperAdmin',
      'Admin',
      'Gerente',
      'Champion',
    ]
  },
  {
    father: "/plugins/my-plugins",
    path: "/dafiti-config",
    name: "Dafiti Plugin",
    component: DafitiConfig,
    layout: "/admin",
    isFather: false,
    son: true,
    grandSon: true,
    icon: faPuzzlePiece,
    img: ImgCardSix,
    idMatch: 50,
    rolAccess: [
      'SuperAdmin',
      'Admin',
      'Gerente',
      'Champion',
    ]
  },
  {
    father: "/plugins/my-plugins",
    path: "/pedidos-ya-config",
    name: "PedidosYa Plugin",
    component: FormIndex,
    layout: "/admin",
    isFather: false,
    son: true,
    grandSon: true,
    icon: faPuzzlePiece,
    img: ImgCardSix,
    idMatch: 65,
    rolAccess: [
      'SuperAdmin',
      'Admin',
      'Gerente',
    ]
  },
  {
    father: "/plugins/my-plugins",
    path: "/anican-config",
    name: "Anican Plugin",
    component: FormIndexAnican,
    layout: "/admin",
    isFather: false,
    son: true,
    grandSon: true,
    icon: faPuzzlePiece,
    img: ImgCardThree,
    idMatch: 65,
    rolAccess: [
      'SuperAdmin',
      'Admin',
      'Gerente',
    ]
  },
  {
    father: "/plugins/my-plugins",
    path: "/woocommerce-config",
    name: "Woocommerce Plugin",
    component: FormIndexWoo,
    layout: "/admin",
    isFather: false,
    son: true,
    grandSon: true,
    icon: faPuzzlePiece,
    img: ImgCardThree,
    idMatch: 65,
    rolAccess: [
      'SuperAdmin',
      'Admin',
      'Gerente',
    ]
  },
  //* Sons' my-plugins end
  /**
   * * user enterprise routes begin
   */
  {
    father: "/enterprise",
    path: "/new-enterprise",
    name: "Nueva Empresa",
    component: UserEnterpriseNew,
    layout: "/admin",
    isFather: false,
    son: true,
    grandSon: true,
    icon: faPuzzlePiece,
    img: ImgCardSix,
    idMatch: 51,
    rolAccess: [
      'SuperAdmin',
      'Admin',
      'Gerente',
      'Champion',
    ]
  },
  {
    father: "/enterprise",
    path: "/update-enterprise/:id",
    name: "Actualiza tu Empresa",
    component: UserEnterpriseUpdate,
    layout: "/admin",
    isFather: false,
    son: true,
    grandSon: true,
    icon: faPuzzlePiece,
    img: ImgCardSix,
    idMatch: 52,
    rolAccess: [
      'SuperAdmin',
      'Admin',
      'Gerente',
      'Champion',
    ]
  },
  {
    father: "/enterprise",
    path: "/das-enterprise",
    name: "Dashboard",
    component: UserEnterpriseDas,
    layout: "/admin",
    isFather: false,
    son: true,
    grandSon: true,
    icon: faPuzzlePiece,
    img: ImgCardSix,
    idMatch: 53,
    rolAccess: [
      'SuperAdmin',
      'Admin',
      'Gerente',
      'Champion',
    ]
  },


  {
    father: "/admin",
    path: "/debug-screen",
    name: "Debug Screen",
    component: DebugScreen,
    layout: "/admin",
    isFather: false,
    son: false,
    grandSon: false,
    icon: faPuzzlePiece,
    img: ImgCardSix,
    idMatch: 54,
    rolAccess: [
      'SuperAdmin',
      'Admin',
      'Gerente',
      'Champion',
    ]
  },
  {
    father: "/admin",
    path: "/translate-categories",
    name: "Traducir categorias Amazon",
    component: TranslateCategorie,
    layout: "/admin",
    isFather: true,
    son: false,
    grandSon: false,
    icon: faLanguage,
    img: "",
    idMatch: 55,
    rolAccess: [
      'SuperAdmin',
      'Admin',
      'Gerente',
      'Champion',
    ], enterpriseBlock: []
  },
  {
    father: "/admin",
    path: "/link-categories",
    name: "Vincular categorias Amazon ML",
    component: LinkCategories,
    layout: "/admin",
    isFather: true,
    son: false,
    grandSon: false,
    icon: faLink,
    img: "",
    idMatch: 56,
    rolAccess: [
      'SuperAdmin',
      'Admin',
      'Gerente',
      'Champion',
    ], enterpriseBlock: []
  },
  {
    father: "/admin",
    path: "/link-city",
    name: "Vinculacion de Ciudades",
    component: LinkCity,
    layout: "/admin",
    isFather: true,
    son: false,
    grandSon: false,
    icon: faCity,
    img: "",
    idMatch: 57,
    rolAccess: [
      'SuperAdmin',
      'Admin',
      'Gerente',
      'Champion',
    ], enterpriseBlock: []
  },
  // {
  //   father: "/admin",
  //   path: "/plantillas-publicacion",
  //   name: "Plantillas para publicacion",
  //   component: PlantillasPublicacion,
  //   layout: "/admin",
  //   isFather: true,
  //   son: false,
  //   grandSon: false,
  //   icon: faGenderless,
  //   img: "",
  // rolAccess: [], enterpriseBlock: []
  // },
  {
    father: "/plantillas-publicacion",
    path: "/plantillas-publicacion",
    name: "Plantillas para publicacion",
    component: FormPlantilla,
    layout: "/admin",
    isFather: true,
    son: false,
    grandSon: false,
    icon: faGenderless,
    img: "",
    idMatch: 58,
    rolAccess: [
      'SuperAdmin',
      'Admin',
      'Gerente',
    ], enterpriseBlock: []
  },
  {
    father: "/admin",
    path: "/rangos-publicacion",
    name: "Rangos Ml Amazon",
    component: Index,
    layout: "/admin",
    isFather: true,
    son: false,
    grandSon: false,
    icon: faGenderless,
    img: "",
    idMatch: 59,
    rolAccess: [
      'SuperAdmin',
      'Admin',
    ],
    enterpriseBlock: [],
    third: true,
  },
  {
    father: "/linkeo-masivo",
    path: "/linkeo-masivo",
    name: "Linkeo Massivo",
    component: LinkeoMasivo,
    layout: "/admin",
    isFather: true,
    son: false,
    grandSon: false,
    icon: faGenderless,
    img: "",
    idMatch: 60,
    rolAccess: [
      'SuperAdmin',
      'Admin',
      'Gerente',
    ], enterpriseBlock: []
  },
  {
    father: "/admin",
    path: "/robot-facebook",
    name: "Robbot Facebook",
    component: AnotherScraping,
    layout: "/admin",
    isFather: true,
    son: false,
    grandSon: false,
    icon: faRobot,
    img: "",
    idMatch: 61,
    rolAccess: [
      'SuperAdmin',
      'Admin',
      'Gerente',
      'Champion',
    ], enterpriseBlock: []
  },

  // Testing
  {
    path: "/testing/admin-enterprise",
    name: "Testing Admin Enterprise",
    component: TestingAdminEnterprise,
    layout: "/admin",
    isFather: false,
    son: false,
    grandSon: false,
    icon: faGenderless,
    img: "",
    idMatch: 62,
    rolAccess: [
      'SuperAdmin',
      'Admin',
      'Gerente',
      'Champion',
    ]
  },

  {
    path: "/testing/admin",
    name: "Testing Admin",
    component: TestingAdmin,
    layout: "/admin",
    isFather: false,
    son: false,
    grandSon: false,
    icon: faGenderless,
    img: "",
    idMatch: 63,
    rolAccess: [
      'SuperAdmin'
    ]
  },
  {
    path: "/testing/admin-woo",
    name: "Testing Admin woo",
    component: AdminWoo,
    layout: "/admin",
    isFather: false,
    son: false,
    grandSon: false,
    icon: faGenderless,
    img: "",
    idMatch: 63,
    rolAccess: [
      'SuperAdmin',
      'Admin',
      'Gerente',
      'Champion',
    ]
  },

  {
    path: "/testing/xlsx",
    name: "Xlsx",
    component: UploadProductsXlsx,
    layout: "/admin",
    isFather: false,
    son: false,
    grandSon: false,
    icon: faGenderless,
    img: "",
    idMatch: 64,
    rolAccess: [
      'SuperAdmin',
      'Admin',
      'Gerente'
    ]
  },
  {
    path: "/testing-peya/xlsx",
    name: "Xlsx peya",
    component: uploadProductsPeyaXlsx,
    layout: "/admin",
    isFather: false,
    son: false,
    grandSon: false,
    icon: faGenderless,
    img: "",
    idMatch: 71,
    rolAccess: [
      'SuperAdmin',
      'Admin',
      'Gerente'
    ]
  },
  {
    path: "/testing/delete-xlsx",
    name: "Xlsx ml delete",
    component: deletedProductsMLXlsx,
    layout: "/admin",
    isFather: false,
    son: false,
    grandSon: false,
    icon: faGenderless,
    img: "",
    idMatch: 72,
    rolAccess: [
      'SuperAdmin',
      'Admin',
      'Gerente'
    ]
  },
  {
    path: "/testing/delete-categories-xlsx",
    name: "Xlsx woo",
    component: UploadCategoriesXlsx,
    layout: "/admin",
    isFather: false,
    son: false,
    grandSon: false,
    icon: faGenderless,
    img: "",
    idMatch: 72,
    rolAccess: [
      'SuperAdmin',
      'Admin',
      'Gerente'
    ]
  },
  {
    path: "/testing/link-categories-woo-xlsx",
    name: "Xlsx woo",
    component: UpdateCategoriesXlsx,
    layout: "/admin",
    isFather: false,
    son: false,
    grandSon: false,
    icon: faGenderless,
    img: "",
    idMatch: 72,
    rolAccess: [
      'SuperAdmin',
      'Admin',
      'Gerente'
    ]
  },
  {
    path: "/testing/link-products-woo-xlsx",
    name: "Xlsx woo",
    component: UpdateProductsXlsx,
    layout: "/admin",
    isFather: false,
    son: false,
    grandSon: false,
    icon: faGenderless,
    img: "",
    idMatch: 72,
    rolAccess: [
      'SuperAdmin',
      'Admin',
      'Gerente'
    ]
  },
  {
    path: "/testing/delete-products-from-xls-ml",
    name: "Xlsx",
    component: DeleteByExcellMLKBT,
    layout: "/admin",
    isFather: false,
    son: false,
    grandSon: false,
    icon: faGenderless,
    img: "",
    idMatch: 76,
    rolAccess: [
      'SuperAdmin',
      'Admin',
    ]
  },

  {
    path: "/testing/link-products-woo-xlsx-amazon",
    name: "Xlsx woo",
    component: UpdateProductsXlsxAmazo,
    layout: "/admin",
    isFather: false,
    son: false,
    grandSon: false,
    icon: faGenderless,
    img: "",
    idMatch: 72,
    rolAccess: [
      'SuperAdmin',
      'Admin',
      'Gerente'
    ]
  },


  {
    father: "reports",
    path: "/amazon/grid",
    name: "Reportes de Amazon",
    component: GridReports,
    layout: "/admin",
    isFather: true, //change
    son: false,
    grandSon: false,
    icon: faChartBar,
    img: "",
    idMatch: 65,
    rolAccess: [
      'SuperAdmin',
      'Admin',
      'Gerente',
    ],
    enterpriseBlock: []
  },
  {
    father: "pedidosya",
    path: "/pedidos-ya/update-section/:id",
    name: "Actualizar Seccion Pedidos Ya",
    component: GridReports,
    layout: "/admin",
    isFather: false, //change
    son: true,
    grandSon: false,
    icon: faChartBar,
    img: "",
    idMatch: 68,
    rolAccess: [
      'SuperAdmin',
      'Admin',
      'Gerente',
      'Champion',
    ],
    enterpriseBlock: []
  },
  {
    father: "pedidosya",
    path: "/pedidos-ya/sync-sections",
    name: "Sincronizar Secciones Pedidos Ya",
    component: GridReports,
    layout: "/admin",
    isFather: false, //change
    son: true,
    grandSon: false,
    icon: faChartBar,
    img: "",
    idMatch: 69,
    rolAccess: [
      'SuperAdmin',
      'Admin',
      'Gerente',
      'Champion',
    ],
    enterpriseBlock: []
  },
  {
    father: "pedidosya",
    path: "/send-products-m-to-m",
    name: "MarketPlace to MarketPlace",
    component: GridListProducts,
    layout: "/admin",
    isFather: true, //change
    son: false,
    grandSon: false,
    icon: faExchangeAlt,
    img: "",
    idMatch: 70,
    rolAccess: [
      'SuperAdmin',
      'Admin',
      'Gerente',
      'Champion',
    ],
    enterpriseBlock: [],
    third: true
  },
  {
    father: "/send-products-m-to-m",
    // path: "/detail-mtm/:id",
    name: "Detalle del Producto",
    component: DetailProductMTM,
    layout: "/admin",
    isFather: false,
    son: false,
    grandSon: false,
    icon: faBoxes,
    img: ImgCardFour,
    idMatch: 86,
    rolAccess: [
      'SuperAdmin',
      'Admin',
      'Gerente',
      'Champion',
    ],
    third: true
  },
  {
    father: "admin",
    path: "/remove-background-image",
    name: "Remover Background",
    component: IndexRemoveBackground,
    layout: "/admin",
    isFather: true, //change
    son: false,
    grandSon: false,
    icon: faRemoveFormat,
    img: "",
    idMatch: 71,
    rolAccess: [
      'SuperAdmin',
      'Admin',
      'Gerente',
      'Champion',
    ],
    enterpriseBlock: []
  },
  {
    path: "/load-url/xlsx",
    name: "Cargar URLs Amazon",
    component: UploadUrls,
    layout: "/admin",
    isFather: true,
    son: false,
    grandSon: false,
    icon: faGenderless,
    img: "",
    idMatch: 72,
    rolAccess: [
      'SuperAdmin',
      'Admin',
      'Gerente'
    ],
    enterpriseBlock: []
  },
  {
    path: "/duplicate",
    name: "Duplicar prod entre empresas SCRAP",
    component: Duplicate,
    layout: "/admin",
    isFather: true,
    son: false,
    grandSon: false,
    icon: faGenderless,
    img: "",
    idMatch: 73,
    rolAccess: [
      'SuperAdmin',
      'Admin',
      'Gerente',
      'Champion',
    ],
    enterpriseBlock: []
  },
  {
    father: "admin",
    path: "/rates-woo",
    name: "Campañas Woo",
    component: FormIndexWooCampaign,
    layout: "/admin",
    isFather: true,
    son: false,
    grandSon: false,
    icon: faTags,
    img: "",
    idMatch: 85,
    rolAccess: [
      'SuperAdmin',
      'Admin',
      'Gerente',
    ],
    enterpriseBlock: []
  },{
    father: "admin",
    path: "/rates-cashea",
    name: "Campañas de Cashea",
    component: CasheaCampaign,
    layout: "/admin",
    isFather: true,
    son: false,
    grandSon: false,
    icon: faTags,
    img: "",
    idMatch: 85,
    rolAccess: [
      'SuperAdmin',
      'Admin',
      'Gerente',
    ],
    enterpriseBlock: []
  },
  {
    father: "admin",
    path: "/scanner",
    name: "Escáner",
    component: ShowProductInfo,
    layout: "/admin",
    isFather: true,
    son: false,
    grandSon: false,
    icon: faShoppingBag,
    img: "",
    idMatch: 87,
    rolAccess: [
      'Scanner',
    ],
    enterpriseBlock: []
  },
  /**
   * formulario de integracion con Mercantil
   */
  {
    father: "admin",
    path: "/mercantil-config",
    name: "Integración con Mercantil",
    component: FormConfigMercantil,
    layout: "/admin",
    isFather: true,
    son: false,
    grandSon: false,
    icon: faBuilding,
    img: "",
    idMatch: 65,
    rolAccess: [
      'SuperAdmin',
      'Admin',
      'Gerente',
    ],
    enterpriseBlock: []
  },
  {
    father: "/plugins/my-plugins",
    path: "/cashea-config",
    name: "Cashea Plugin",
    component: CasheaConfig,
    layout: "/admin",
    isFather: false,
    son: true,
    grandSon: true,
    icon: faPuzzlePiece,
    img: ImgCardSix,
    idMatch: 66,
    rolAccess: [
      'SuperAdmin',
      'Admin',
      'Gerente',
      'Champion',
    ]
  },
];

const items = Routes.sort((a, b) => {
  if (a.name === "Dashboard") return -1;
  if (b.name === "Dashboard") return 1;
  return a.name.localeCompare(b.name);
});

export default items.filter(v => !!v);
