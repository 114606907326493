import React, { useContext, useEffect, useState } from "react";
import ListOtherProductsStyle from "../../../../../assets/css/components/admin/products/ListOtherProducts.css";
import { Row, Col, Image } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlusCircle } from "@fortawesome/free-solid-svg-icons";
import { Form } from "react-bootstrap";
import {
  keyUserId,
  keyAccessToken,
} from "../../../../../assets/js/SessionStorageNames";
import axios from "axios";
import { GlobalContext } from "../../../../../contexts/GlobalContext";
import { getVariantDescription } from "../../../../../assets/js/productHelper";
import FilterListOtherProduct from "./FilterListOtherProducts";
import Pager from "../../../../general/Pager";

export default function ListOtherProducts(props) {
  const pageItemCount = 10;
  const [axiosCancelTokenSource] = useState(axios.CancelToken.source());
  const globalContext = useContext(GlobalContext);
  const [pluginsOpt, setPluginsOpt] = useState([]);
  const [selectedPlugin, setSelectedPlugin] = useState("");
  const [linkableProducts, setLinkableProducts] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalProducts, setTotalProducts] = useState(1);
  const [filter, setFilter] = useState("");
  const [excludeAlreadyLinked, setExcludeAlreadyLinked] = useState(false);
  const [brands, setBrands] = useState([]);
  const [selectedBrand, setSelectedBrand] = useState("");

  /**
   * Handle the axios error
   * @param {Error} err The thrown error
   * @param {string} msg A message to show in the modal
   * @param {boolean} consoleLog If should log the error in the console
   */
  const handleAxiosError = (err, msg = null, consoleLog = true) => {
    if (axios.isCancel(err)) return; //Don't show axios cancel error
    if (msg) globalContext.showModalWithMsg(msg);
    if (consoleLog) console.log("Error: ", err, "Response: ", err.response);
  };

  /**
   * Updates the filter
   */
  const updateFilter = (filter) => {
    setFilter(filter);
    setCurrentPage(1);
  };

  /**
   * Define if linked products should be listed or not
   */
  const toggleExcludeAlreadyLinked = (e) => {
    setExcludeAlreadyLinked(!excludeAlreadyLinked);
    setCurrentPage(1);
  };

  /**
   * Manage the dropdown change
   * @param {Event} e
   */
  const changeSelected = (e) => {
    const name = e.target.name;
    const sel = e.target.value;
    
    switch (name) {
      case "selectedPlugin":
        setSelectedPlugin(sel);
        setSelectedBrand("");
        break;

      case "selectedBrand":
        setSelectedBrand(sel);
        break;
    }
  };

  /**
   * Load the user available plugins
   */
  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_BACKEND_URL}/v1/main-plugin/available`, {
        cancelToken: axiosCancelTokenSource.token,
        headers: {
          "x-api-key": process.env.REACT_APP_API_KEY,
          "x-access-token": localStorage.getItem(keyAccessToken),
          "x-user-id": localStorage.getItem(keyUserId),
        },
      })
      .then((resp) => {
        const data = resp.data.data;
        const options = [];

        if (data.length > 0) {
          for (const opt of data) {
            //Skip the excludePlugin
            if (opt.id === props.excludePlugin) continue;

            options.push({
              id: opt.id,
              name: opt.name,
            });
          }
        }

        setPluginsOpt(options);
      })
      .catch((err) =>
        handleAxiosError(
          err,
          "Disculpe, hubo un error al intentar cargar la lista de plugins."
        )
      );
  }, []);

  /**
   * Load linkable products
   */
  useEffect(() => {
    const entId = globalContext.currentEnterpriseId;
    const headerParams = {
      "x-api-key": process.env.REACT_APP_API_KEY,
      "x-access-token": localStorage.getItem(keyAccessToken),
      "x-user-id": localStorage.getItem(keyUserId),
    };

    switch (selectedPlugin) {
      case "odoo":
        globalContext.setLoaderPage(true);
        axios
          .get(`${process.env.REACT_APP_BACKEND_URL}/v1/odoo/${entId}`, {
            cancelToken: axiosCancelTokenSource.token,
            headers: headerParams,
            params: {
              pageNumber: currentPage,
              pageItemCount: pageItemCount,
              filter: {
                title: filter,
                barCode: filter,
                variBarCode: filter,
                sku: filter,
                variSku: filter,
                skipBaseWithVariants: true,
                filterLinkedTo: excludeAlreadyLinked ? "unlinked" : "",
              },
            },
          })
          .then((resp) => {
            const data = resp.data.data;
            const products = data.products.map((p) => {
              const prod = p;

              //Set the variant description (if the product is a variant)
              if (prod.isVariant) {
                prod.variantDesc = getVariantDescription(prod, "odoo");
              }

              return prod;
            });

            setLinkableProducts(products);
            setTotalProducts(data.totalProducts);
          })
          .catch((err) =>
            handleAxiosError(
              err,
              "Disculpe, hubo un error al intentar cargar la lista de productos."
            )
          )
          .finally(() => {
            globalContext.setLoaderPage(false);
          });
        break; //end odoo

      case "ml":
        globalContext.setLoaderPage(true);
        axios
          .get(`${process.env.REACT_APP_BACKEND_URL}/v1/ml-product/${entId}`, {
            cancelToken: axiosCancelTokenSource.token,
            headers: headerParams,
            params: {
              pageNumber: currentPage,
              pageItemCount: pageItemCount,
              filter: {
                title: filter,
                barCode: filter,
                variBarCode: filter,
                sku: filter,
                variSku: filter,
                skipBaseWithVariants: true,
                filterLinkedTo: excludeAlreadyLinked ? "unlinked" : "",
                brand: selectedBrand,
              },
            },
          })
          .then((resp) => {
            const data = resp.data.data;
            const products = data.products.map((p) => {
              const prod = p;

              //Set the variant description (if the product is a variant)
              if (prod.isVariant) {
                prod.variantDesc = getVariantDescription(prod, "ml");
              }

              if (prod.attrib.official_store_id && props.brands.length > 0) {
                const brand = props.brands.filter(
                  (b) => b.id === prod.attrib.official_store_id
                );

                if (brand.length > 0) {
                  prod.mlBrandName = brand[0].name;
                }
              }

              return prod;
            });

            setLinkableProducts(products);
            setTotalProducts(data.totalProducts);
          })
          .catch((err) =>
            handleAxiosError(
              err,
              "Disculpe, hubo un error al intentar cargar la lista de productos."
            )
          )
          .finally(() => {
            globalContext.setLoaderPage(false);
          });
        break; //end ml
      /**
       * linio
       *  */
      case "ln":
        globalContext.setLoaderPage(true);
        axios
          .get(
            `${process.env.REACT_APP_BACKEND_URL}/v1/linio/getproducts/${entId}`,
            {
              cancelToken: axiosCancelTokenSource.token,
              headers: headerParams,
              params: {
                pageNumber: currentPage,
                pageItemCount: pageItemCount,
                filter: {
                  title: filter,
                  barCode: filter,
                  variBarCode: filter,
                  sellerSku: filter,
                  variSku: filter,
                  skipBaseWithVariants: true,
                  filterLinkedTo: excludeAlreadyLinked ? "unlinked" : "",
                  brand: selectedBrand,
                },
              },
            }
          )
          .then((resp) => {
            const data = resp.data.data;
            const products = data.products.map((p) => {
              const prod = p;

              //Set the variant description (if the product is a variant)
              if (prod.isVariant) {
                prod.variantDesc = getVariantDescription(prod, "ln");
              }

              if (prod.attrib.official_store_id && props.brands.length > 0) {
                const brand = props.brands.filter(
                  (b) => b.id === prod.attrib.official_store_id
                );

                if (brand.length > 0) {
                  prod.mlBrandName = brand[0].name;
                }
              }

              return prod;
            });

            setLinkableProducts(products);
            setTotalProducts(data.totalProducts);
          })
          .catch((err) =>
            handleAxiosError(
              err,
              "Disculpe, hubo un error al intentar cargar la lista de productos."
            )
          )
          .finally(() => {
            globalContext.setLoaderPage(false);
          });
        break;
      case "df":
        globalContext.setLoaderPage(true);
        axios
          .get(
            `${process.env.REACT_APP_BACKEND_URL}/v1/dafiti-product/getproducts/${entId}`,
            {
              cancelToken: axiosCancelTokenSource.token,
              headers: headerParams,
              params: {
                pageNumber: currentPage,
                pageItemCount: pageItemCount,
                filter: {
                  title: filter,
                  barCode: filter,
                  variBarCode: filter,
                  sellerSku: filter,
                  variSku: filter,
                  skipBaseWithVariants: true,
                  filterLinkedTo: excludeAlreadyLinked ? "unlinked" : "",
                  brand: selectedBrand,
                },
              },
            }
          )
          .then((resp) => {
            const data = resp.data.data;
            const products = data.products.map((p) => {
              const prod = p;

              //Set the variant description (if the product is a variant)
              if (prod.isVariant) {
                prod.variantDesc = getVariantDescription(prod, "df");
              }

              if (prod.attrib.official_store_id && props.brands.length > 0) {
                const brand = props.brands.filter(
                  (b) => b.id === prod.attrib.official_store_id
                );

                if (brand.length > 0) {
                  prod.mlBrandName = brand[0].name;
                }
              }

              return prod;
            });

            setLinkableProducts(products);
            setTotalProducts(data.totalProducts);
          })
          .catch((err) =>
            handleAxiosError(
              err,
              "Disculpe, hubo un error al intentar cargar la lista de productos."
            )
          )
          .finally(() => {
            globalContext.setLoaderPage(false);
          });
        break; // df
      case "coco":
        console.log("para coco");
        axios
          .post(
            `${process.env.REACT_APP_BACKEND_URL}/v1/productos-coco/coco-get-products/${entId}`,

            {
              pageNumber: currentPage,
              pageItemCount: pageItemCount,
              filter: {
                title: filter,
                barCode: filter,
                variBarCode: filter,
                sellerSku: filter,
                variSku: filter,
                skipVariants: true,
              },
              checkIsFullyLinked: true,
              includeVariantData: true,
            },
            {
              cancelToken: axiosCancelTokenSource.token,
              headers: {
                "x-api-key": process.env.REACT_APP_API_KEY,
                "x-access-token": localStorage.getItem(keyAccessToken),
                "x-user-id": localStorage.getItem(keyUserId),
              },
            }
          )
          .then((resp) => {
            const data = resp.data.data;
            const products = data.products.map((p) => {
              const prod = p;

              //Set the variant description (if the product is a variant)
              if (prod.isVariant) {
                prod.variantDesc = getVariantDescription(prod, "coco");
              }

              if (prod.attrib.official_store_id && props.brands.length > 0) {
                const brand = props.brands.filter(
                  (b) => b.id === prod.attrib.official_store_id
                );

                if (brand.length > 0) {
                  prod.mlBrandName = brand[0].name;
                }
              }

              return prod;
            });

            setLinkableProducts(products);
            setTotalProducts(data.totalProducts);
          });
        break; //End coco

      case "fl":
        globalContext.setLoaderPage(true);
        axios
          .get(
            `${process.env.REACT_APP_BACKEND_URL}/v1/fala-product/getproducts/${entId}`,
            {
              cancelToken: axiosCancelTokenSource.token,
              headers: headerParams,
              params: {
                pageNumber: currentPage,
                pageItemCount: pageItemCount,
                filter: {
                  title: filter,
                  barCode: filter,
                  variBarCode: filter,
                  sellerSku: filter,
                  variSku: filter,
                  skipBaseWithVariants: true,
                  filterLinkedTo: excludeAlreadyLinked ? "unlinked" : "",
                  brand: selectedBrand,
                },
              },
            }
          )
          .then((resp) => {
            const data = resp.data.data;
            const products = data.products.map((p) => {
              const prod = p;

              //Set the variant description (if the product is a variant)
              if (prod.isVariant) {
                prod.variantDesc = getVariantDescription(prod, "fl");
              }

              if (prod.attrib.official_store_id && props.brands.length > 0) {
                const brand = props.brands.filter(
                  (b) => b.id === prod.attrib.official_store_id
                );

                if (brand.length > 0) {
                  prod.mlBrandName = brand[0].name;
                }
              }

              return prod;
            });

            setLinkableProducts(products);
            setTotalProducts(data.totalProducts);
          })
          .catch((err) =>
            handleAxiosError(
              err,
              "Disculpe, hubo un error al intentar cargar la lista de productos."
            )
          )
          .finally(() => {
            globalContext.setLoaderPage(false);
          });
        break;
      
      case "cashea":
        globalContext.setLoaderPage(true);
          axios
           .get(`${process.env.REACT_APP_BACKEND_URL}/v1/cashea-prod/get-products`, {
             cancelToken: axiosCancelTokenSource.token,
             headers: {
               "x-api-key": process.env.REACT_APP_API_KEY,
               "x-access-token": localStorage.getItem(keyAccessToken),
               "x-user-id": localStorage.getItem(keyUserId),
             },
             params: {
               name: selectedBrand,
               pageNumber: currentPage,
               pageItemCount: pageItemCount,
             }
           })
           .then((resp) => {
             const data = Object.values(resp.data.data.products);
             setLinkableProducts(data);
             setTotalProducts(resp.data.data.totalProducts)
           })
           .catch((err) => handleAxiosError(err))
          .finally(() => {
            globalContext.setLoaderPage(false);
          });
        break;
      default:
        setLinkableProducts([]);
        break;
    }
  }, [
    selectedPlugin,
    selectedBrand,
    currentPage,
    filter,
    excludeAlreadyLinked,
    props.productId,
  ]);

  /**
   * Load the brands list
   */

  useEffect(() => {
    const entId = globalContext.currentEnterpriseId;
    if (!entId) return;

    if(selectedPlugin === 'ml'){
      axios
      .get(`${process.env.REACT_APP_BACKEND_URL}/v1/ml-brand/${entId}`, {
        cancelToken: axiosCancelTokenSource.token,
        headers: {
          "x-api-key": process.env.REACT_APP_API_KEY,
          "x-access-token": localStorage.getItem(keyAccessToken),
          "x-user-id": localStorage.getItem(keyUserId),
        },
      })
      .then((resp) => {
        const brands = resp.data.data.brands;
        setBrands(brands);
      })
      .catch((err) => handleAxiosError(err));
    }

    if(selectedPlugin === "cashea"){
      axios
      .get(`${process.env.REACT_APP_BACKEND_URL}/v1/merchants-name/merchantsName`, {
        cancelToken: axiosCancelTokenSource.token,
        headers: {
          "x-api-key": process.env.REACT_APP_API_KEY,
          "x-access-token": localStorage.getItem(keyAccessToken),
          "x-user-id": localStorage.getItem(keyUserId),
        },
        params: {
          name: 'all'
        }
      })
      .then((resp) => {
        const brands = resp.data.data;
        setBrands(Object.values(brands));
      })
      .catch((err) => handleAxiosError(err));
    }
  }, [globalContext.currentEnterpriseId, selectedPlugin]);
  
  /**
   * Cancel axios requests before unmount
   */
  useEffect(() => {
    return () => {
      axiosCancelTokenSource.cancel("Canceled by unmount");
      globalContext.setLoaderPage(false);
    };
    //eslint-disable-next-line
  }, []);

  /**
   * Add a product to the linked list
   * @param {any} product The product to add
   */
  const addLinked = (product) => {
    const newLinked = props.linked;
    product.plugin = selectedPlugin;

    newLinked.push({
      saved: false,
      priceMultiplier: 1,
      product,
    });

    props.updateLinkedList(newLinked);
  };

  return (
    <ListOtherProductsStyle>
      <div className='list-other-products'>
        <Row>
          <Col xs={12} sm={12} md={12} lg={12} xl={12}>
            <div className='container-header'>
              <div className='ActionsContent'>
                <Row>
                  <Col
                    xs={12}
                    sm={12}
                    md={(selectedPlugin === "ml" || selectedPlugin === "cashea") ? 6 : 12}
                    lg={(selectedPlugin === "ml" || selectedPlugin === "cashea") ? 6 : 12}
                    xl={(selectedPlugin === "ml" || selectedPlugin === "cashea") ? 6 : 12}
                    className='pt-2 pb-2'
                  >
                    <Form.Control
                      as='select'
                      onChange={changeSelected}
                      value={selectedPlugin || ""}
                      name='selectedPlugin'
                      className='text'
                    >
                      <option key='' value=''>
                        Seleccione un plugin
                      </option>
                      {pluginsOpt.map(
                        ({ id, name }) =>
                          name && (
                            <option key={id} value={id}>
                              {name}
                            </option>
                          )
                      )}
                    </Form.Control>
                  </Col>
                  {(selectedPlugin === "ml" || selectedPlugin === "cashea") && (
                    <Col
                      xs={12}
                      sm={12}
                      md={6}
                      lg={6}
                      xl={6}
                      className='d-flex align-items-center pt-2 pb-2'
                    >
                      <Form.Control
                        as='select'
                        onChange={changeSelected}
                        value={selectedBrand || ""}
                        name='selectedBrand'
                      >
                        <option key='' value=''>
                          Seleccione una tienda
                        </option>
                        {selectedPlugin === 'ml' ? brands.map(
                          ({ name, id }) =>
                            name !== "" && (
                              <option key={id} value={id}>
                                {name}
                              </option>
                            )
                        ): brands.map(
                          ({ name, _id }) =>
                            name !== "" && (
                              <option key={_id} value={name}>
                                {name}
                              </option>
                            )
                        )}
                      </Form.Control>
                    </Col>
                  )}
                  <Col
                    xs={12}
                    sm={12}
                    md={6}
                    lg={6}
                    xl={6}
                    className='pt-2 pb-2 filterList'
                  >
                    <FilterListOtherProduct
                      filter={filter}
                      updateFilter={updateFilter}
                    />
                  </Col>
                  <Col
                    xs={12}
                    sm={12}
                    md={6}
                    lg={6}
                    xl={6}
                    className='d-flex justify-content-center align-items-center pt-2 pb-2 filterList'
                  >
                    <button
                      className='btn-includ-assoc'
                      title={
                        excludeAlreadyLinked
                          ? "Incluir ya asociados"
                          : "Sin ya asociados"
                      }
                      onClick={toggleExcludeAlreadyLinked}
                    >
                      {excludeAlreadyLinked ? "Con Asociados" : "Sin Asociados"}
                    </button>
                  </Col>
                </Row>
              </div>
            </div>
          </Col>
          <Col xs={12}>
            {linkableProducts.length > 0 && (
              <div className='limiter'>
                <div className='container-table100'>
                  <div className='wrap-table100 col-12 mt-3'>
                    <div className='table100'>
                      <table>
                        <thead>
                          <tr className='table100-head'>
                            <th className='column1'>IMAGEN</th>
                            <th className='column2'>TITULO</th>
                            <th className='column3'></th>
                          </tr>
                        </thead>
                        <tbody>
                          {linkableProducts.map((p) => {
                            //If the product is already linked, don't show the button
                            const plusButton =
                              (p.linkedTo && p.linkedTo.length > 0) ||
                              props.linked.filter(
                                (l) =>
                                  l.product._id.toString() === p._id.toString()
                              ).length > 0 ? null : (
                                <button
                                  className='btn-action-list'
                                  onClick={() => {
                                    addLinked(p);
                                  }}
                                >
                                  <FontAwesomeIcon icon={faPlusCircle} />
                                </button>
                              );

                            let thumbnailSrc = "";

                            if (p.thumbnail) {
                              if (p.plugin === "odoo") {
                                thumbnailSrc =
                                  p.thumbnail &&
                                  typeof p.thumbnail == "object" &&
                                  p.thumbnail.url
                                    ? p.thumbnail.url
                                    : p.thumbnail &&
                                      typeof p.thumbnail == "string"
                                    ? `data:image/png;base64,${p.thumbnail}`
                                    : false;
                              } else {
                                thumbnailSrc = p.thumbnail;
                              }
                            }
                            return (
                              <tr key={p._id}>
                                <td className='column1'>
                                  <Image
                                    src={thumbnailSrc}
                                    alt=''
                                    className='imgProduct'
                                  />
                                </td>
                                <td className='column2'>
                                  <span className='nv'>{p.title}</span>
                                  {p.mlBrandName && (
                                    <>
                                      <br />
                                      <span>
                                        <i>{p.mlBrandName}</i>
                                      </span>
                                    </>
                                  )}
                                  {p.attrib.status && (
                                    <span>
                                      <br />
                                      Estatus: {p.attrib.status}
                                    </span>
                                  )}
                                  <p className='dv'>{p.variantDesc}</p>
                                </td>
                                <td className='column3'>{plusButton}</td>
                              </tr>
                            );
                          })}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </Col>
        </Row>

        {linkableProducts.length > 0 && (
          <Pager
            handleSetPage={setCurrentPage}
            totalResults={totalProducts}
            currentPage={currentPage}
            resultsPerPage={pageItemCount}
          />
        )}
      </div>
    </ListOtherProductsStyle>
  );
}
