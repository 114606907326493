import axios from "axios";
import { keyAccessToken, keyUserId } from "../../../../../../assets/js/SessionStorageNames";

export const sendCampaign = (enterpriseId, pricelistId, plusPercent, dateFrom, dateTo, nameCampaign) => {
    const res = axios.post(`${process.env.REACT_APP_BACKEND_URL}/v1/cashea-campaign/send-from-odoo-pricelist`,
        {
            pricelistId,
            plusPercent,
            enterpriseId,
            dateFrom,
            dateTo,
            nameCampaign

        }, {
        headers: {
            "x-api-key": process.env.REACT_APP_API_KEY,
            "x-access-token": localStorage.getItem(keyAccessToken),
            "x-user-id": localStorage.getItem(keyUserId),
        },
    });

    return res;
}