import axios from "axios";
import { keyAccessToken, keyUserId } from "../../../../../../assets/js/SessionStorageNames";

export const deleteProductsCampaign = (enterpriseId, campaignId) => {
    const res = axios.post(`${process.env.REACT_APP_BACKEND_URL}/v1/cashea-campaign/delete-from-campign-id/${enterpriseId}`,
        {
            campaignId
        }, {
        headers: {
            "x-api-key": process.env.REACT_APP_API_KEY,
            "x-access-token": localStorage.getItem(keyAccessToken),
            "x-user-id": localStorage.getItem(keyUserId),
        },
    })
    return res;
}