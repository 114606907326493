import axios from "axios";
import { keyAccessToken, keyUserId } from "../assets/js/SessionStorageNames";
import { vars } from "../config";

const authHeaders = {
    "x-api-key": vars.apiKey,
    "x-access-token": localStorage.getItem(keyAccessToken),
    "x-user-id": localStorage.getItem(keyUserId),
}

export const api = {
  get: (url, params) =>
    axios.get(url, {
      headers: {
        ...authHeaders
      },
      ...params,
    }),
  post: (url, data) =>
    axios.post(url, data, {
      headers: {
        ...authHeaders
      },
    }),
  patch: (url, data) =>
    axios.patch(url, data, {
      headers: {
        ...authHeaders
      },
    }),
  delete: (url) =>
    axios.delete(url, {
      headers: {
        ...authHeaders
      },
    }),
};